import React from 'react';

export function Button({ children, variant = 'default', ...props }) {
  const baseStyles = 'px-4 py-2 rounded font-semibold transition-colors';
  const variantStyles = {
    default: 'bg-blue-600 text-white hover:bg-blue-700',
    outline: 'bg-transparent border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white',
    ghost: 'bg-transparent text-blue-600 hover:bg-blue-100 hover:bg-opacity-20',
  };
  
  return (
    <button 
      className={`${baseStyles} ${variantStyles[variant]}`} 
      {...props}
    >
      {children}
    </button>
  );
}