import React from 'react';
import ReactDOM from 'react-dom';
import './tailwind.output.css';  // Import the generated Tailwind CSS
import './custom.css';  // Your custom styles
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);