import React, { useState, useRef, useEffect, useCallback } from 'react';
import { GripIcon } from './components/GripIcon';
import { Button } from './components/Button';
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight } from 'lucide-react';

const debounce = (func, delay) => {
  let debounceTimer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const SEOOptimizationApp = () => {
  const [activeTab, setActiveTab] = useState('metadata');
  const [pageTitle, setPageTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [keywords, setKeywords] = useState({ main: '', variations: '', entities: '', lsi: '', yake: '' });
  const [keywordCounts, setKeywordCounts] = useState({});
  const [headingLevelCounts, setHeadingLevelCounts] = useState({});
  const [headingTypeCounts, setHeadingTypeCounts] = useState({});
  const [sections, setSections] = useState([]);
  const [activeInput, setActiveInput] = useState(null);
  const [activeHeadingId, setActiveHeadingId] = useState(null);
  const [isPanelExpanded, setIsPanelExpanded] = useState(false);
  const usedWordsRef = useRef(new Set());
  const [isHeadingSummaryExpanded, setIsHeadingSummaryExpanded] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    pageTitleWordUsage: false,
    metaDescriptionWordUsage: false,
    contentWordUsage: false,
  });
  const dragItem = useRef();
  const dragOverItem = useRef();

  console.log('Rendering SEOOptimizationApp');
  console.log('Active Tab:', activeTab);
      console.log('Sections:', sections);

  const handleKeywordsChange = (type, value) => {
    setKeywords(prev => ({ ...prev, [type]: value }));
  };

  const processKeywords = useCallback(() => {
    const processString = (str) => str.split(/[,\n]+/).map(k => k.trim()).filter(k => k !== '');
    return {
      main: processString(keywords.main),
      variations: processString(keywords.variations),
      entities: processString(keywords.entities),
      lsi: processString(keywords.lsi),
      yake: processString(keywords.yake),
    };
  }, [keywords]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateKeywordCounts = useCallback(() => {
    const processedKeywords = processKeywords();
    const keywordTypes = ['main', 'variations', 'entities', 'lsi', 'yake'];
    
    const initCounts = () => {
      const obj = {};
      keywordTypes.forEach(type => {
        obj[type] = {total: 0, unique: 0};
      });
      return obj;
    };
  
    const counts = {
      pageTitle: initCounts(),
      metaDescription: initCounts(),
    };
  
    const contentCounts = initCounts();
    let contentSectionCount = 0;
  
    const levelCounts = {
      h1: initCounts(),
      h2: initCounts(),
      h3: initCounts(),
      h4: initCounts(),
      h5: initCounts(),
      h6: initCounts(),
    };
  
    const typeCounts = {
      h1: 0, h2: 0, h3: 0, h4: 0, h5: 0, h6: 0
    };
  
    sections.forEach((section, index) => {
      counts[`section${index}`] = initCounts();
      if (section.type === 'heading') {
        typeCounts[`h${section.level}`]++;
      } else {
        contentSectionCount++;
      }
    });
  
    const countKeywords = (text, keywordList, countObject) => {
      const uniqueKeywords = new Set();
      
      keywordList.forEach(keyword => {
        const keywordRegex = new RegExp(`\\b${keyword.toLowerCase()}\\b`, 'g');
        const matches = (text || '').toLowerCase().match(keywordRegex);
        if (matches) {
          countObject.total += matches.length;
          uniqueKeywords.add(keyword);
        }
      });
      
      countObject.unique = uniqueKeywords.size;
    };
  
    keywordTypes.forEach(type => {
      const keywordList = processedKeywords[type];
      countKeywords(pageTitle, keywordList, counts.pageTitle[type]);
      countKeywords(metaDescription, keywordList, counts.metaDescription[type]);
      sections.forEach((section, index) => {
        countKeywords(section.content, keywordList, counts[`section${index}`][type]);
        if (section.type === 'heading') {
          countKeywords(section.content, keywordList, levelCounts[`h${section.level}`][type]);
        } else {
          countKeywords(section.content, keywordList, contentCounts[type]);
        }
      });
    });
  
    setKeywordCounts(counts);
    setHeadingLevelCounts({ ...levelCounts, content: contentCounts });
    setHeadingTypeCounts({ ...typeCounts, content: contentSectionCount });
  }, [processKeywords, pageTitle, metaDescription, sections]);

  const handlePageTitleChange = (newTitle) => {
    setPageTitle(newTitle);
    checkWordUsage();
  };
  
  const handleMetaDescriptionChange = (newDescription) => {
    setMetaDescription(newDescription);
    checkWordUsage();
  };
  
  const handleSectionUpdate = (id, updates) => {
    setSections(prevSections => 
      prevSections.map(section => 
        section.id === id ? { ...section, ...updates } : section
      )
    );
    checkWordUsage();
  };
  
  const checkWordUsage = useCallback(() => {
    const allText = `${pageTitle} ${metaDescription} ${sections.map(s => s.content).join(' ')}`.toLowerCase();
    
    const newUsedWords = new Set();
  
    Object.values(keywords).forEach(keywordList => {
      keywordList.split(/[,\n]+/).forEach(keyword => {
        const trimmedKeyword = keyword.trim();
        const lowerKeyword = trimmedKeyword.toLowerCase();
        // Use word boundary for exact match, but allow for phrases
        const keywordRegex = new RegExp(`\\b${lowerKeyword.replace(/\s+/g, '\\s+')}\\b`, 'gi');
        if (keywordRegex.test(allText)) {
          newUsedWords.add(lowerKeyword);
          newUsedWords.add(trimmedKeyword); // Add the original casing as well
        }
      });
    });
  
    usedWordsRef.current = newUsedWords;
  }, [pageTitle, metaDescription, sections, keywords]);
  
  const addWordToInput = (word) => {
    if (!activeInput) return;
  
    const updateFunction = (prev) => {
      const newValue = prev ? `${prev} ${word}` : word;
      return newValue;
    };
  
    switch (activeInput) {
      case 'pageTitle':
        setPageTitle(updateFunction);
        break;
      case 'metaDescription':
        setMetaDescription(updateFunction);
        break;
      case 'heading':
      case 'content':
        if (activeHeadingId) {
          setSections(prevSections => 
            prevSections.map(section => 
              section.id === activeHeadingId 
                ? { ...section, content: updateFunction(section.content) } 
                : section
            )
          );
        }
        break;
      default:
        break;
    }
    
    // Trigger checkWordUsage immediately
    checkWordUsage();
  };

  const debouncedUpdate = useCallback(debounce(() => {
    updateKeywordCounts();
    checkWordUsage();
  }, 500), [updateKeywordCounts, checkWordUsage]);

  useEffect(() => {
    debouncedUpdate();
    checkWordUsage();
  }, [debouncedUpdate, checkWordUsage, pageTitle, metaDescription, sections, keywords]);

  const handleDragStart = (e, position) => {
    dragItem.current = position;
    e.target.style.opacity = '0.5';
    e.target.style.background = '#1F2937'; // dark background
    e.target.style.color = '#FFFFFF'; // white text
  };

  const handleDragEnter = (e, position) => {
    dragOverItem.current = position;
    e.target.style.backgroundColor = '#374151'; // slightly lighter than the background for hover effect
  };

  const handleDragLeave = (e) => {
    e.target.style.backgroundColor = '';
  };

  const handleDragEnd = (e) => {
    const copyListItems = [...sections];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setSections(copyListItems);
    e.target.style.opacity = '';
    e.target.style.background = '';
    e.target.style.color = '';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.target.style.background = '#1F2937'; // dark background
    e.target.style.color = '#FFFFFF'; // white text
  };

  const addSection = (type, level = null) => {
    setSections([...sections, { 
      id: Date.now().toString(), 
      type, 
      level: type === 'heading' ? level : null,
      content: '' 
    }]);
  };
  
  const removeSection = (id) => {
    setSections(sections.filter(section => section.id !== id));
  };
  
  const updateSection = (id, updates) => {
    setSections(sections.map(section => 
      section.id === id ? { ...section, ...updates } : section
    ));
  };

  const KeywordCount = ({ counts }) => (
    <div className="text-sm text-gray-300 mt-1 mb-4"> {/* Changed from text-gray-600 to text-gray-300 */}
      Keywords: {counts.main.total} ({counts.main.unique} unique) | 
      Variations: {counts.variations.total} ({counts.variations.unique} unique) | 
      Entities: {counts.entities.total} ({counts.entities.unique} unique) | 
      LSI: {counts.lsi.total} ({counts.lsi.unique} unique) | 
      YAKE: {counts.yake.total} ({counts.yake.unique} unique)
    </div>
  );

  const HeadingLevelSummary = ({ headingLevelCounts, headingTypeCounts }) => (
    <div className="overflow-x-auto bg-gray-900">
      <table className="w-full text-sm text-left text-gray-300">
        <thead className="text-xs text-gray-400 uppercase bg-gray-800">
          <tr>
            <th scope="col" className="px-4 py-2 border-b border-gray-700">Level</th>
            <th scope="col" className="px-4 py-2 border-b border-gray-700">Count</th>
            <th scope="col" className="px-4 py-2 border-b border-gray-700">Keywords</th>
            <th scope="col" className="px-4 py-2 border-b border-gray-700">Variations</th>
            <th scope="col" className="px-4 py-2 border-b border-gray-700">Entities</th>
            <th scope="col" className="px-4 py-2 border-b border-gray-700">LSI</th>
            <th scope="col" className="px-4 py-2 border-b border-gray-700">YAKE</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(headingLevelCounts).map(([level, counts], index, array) => (
            <tr key={level} className={`border-b border-gray-700 hover:bg-gray-800 ${index === array.length - 1 ? 'last:border-b-0' : ''}`}>
              <th scope="row" className="px-4 py-2 font-medium text-white whitespace-nowrap">
                {level === 'content' ? 'Content' : level.toUpperCase()}
              </th>
              <td className="px-4 py-2">{headingTypeCounts[level] || 0}</td>
              <td className="px-4 py-2">{counts.main.total} ({counts.main.unique})</td>
              <td className="px-4 py-2">{counts.variations.total} ({counts.variations.unique})</td>
              <td className="px-4 py-2">{counts.entities.total} ({counts.entities.unique})</td>
              <td className="px-4 py-2">{counts.lsi.total} ({counts.lsi.unique})</td>
              <td className="px-4 py-2">{counts.yake.total} ({counts.yake.unique})</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const KeywordInput = ({ type, label }) => {
    const [localValue, setLocalValue] = useState(keywords[type]);

    const handleChange = (e) => {
      setLocalValue(e.target.value);
    };

    const handleBlur = () => {
      handleKeywordsChange(type, localValue);
    };
    
    return (
      <div className="mb-4">
        <label className="block mb-2">{label}:</label>
        <textarea
          className="w-full p-2 border rounded mb-2 bg-gray-800 border-gray-700 text-white"
          rows="4"
          placeholder={`Enter your ${label.toLowerCase()} here, separated by commas or new lines`}
          onChange={handleChange}
          onBlur={handleBlur}
          value={localValue}
        />
        <div className="flex flex-wrap gap-2">
          {processKeywords()[type].map((keyword, index) => (
            <span key={index} className="bg-blue-700 text-white px-2 py-1 rounded">
              {keyword}
            </span>
          ))}
        </div>
      </div>
    );
  };

  const toggleAccordion = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const KeywordDisplay = ({ title, keywordTypes, text, isExpanded, onToggle }) => {
    const processedKeywords = processKeywords();
  
    const getHeadingText = (type) => {
      switch (type) {
        case 'main': return 'Keyword';
        case 'lsi': return 'LSI';
        case 'yake': return 'YAKE';
        default: return type.charAt(0).toUpperCase() + type.slice(1);
      }
    };
  
    const getMatchedKeywords = (keywordList) => {
      return keywordList.filter(keyword => {
        const keywordRegex = new RegExp(`\\b${keyword.toLowerCase()}\\b`, 'gi');
        return keywordRegex.test(text);
      });
    };
  
    return (
      <div className="mt-2 border border-gray-700 rounded">
        <button 
          className="w-full flex justify-between items-center p-2 bg-gray-800 hover:bg-gray-700 transition-colors text-left"
          onClick={onToggle}
        >
          <span className="text-gray-400">{title} Word Usage</span>
          {isExpanded ? <ChevronUp className="h-4 w-4 text-gray-400" /> : <ChevronDown className="h-4 w-4 text-gray-400" />}
        </button>
        {isExpanded && (
          <div className="p-2 bg-gray-900">
            {keywordTypes.map(type => {
              const matchedKeywords = getMatchedKeywords(processedKeywords[type]);
              return (
                <div key={type} className="mb-2">
                  <h4 className="text-sm font-semibold text-gray-400 mb-1">
                    {getHeadingText(type)}
                  </h4>
                  <div className="flex flex-wrap gap-2">
                    {matchedKeywords.map((keyword, index) => (
                      <span key={index} className="bg-blue-700 text-white px-2 py-1 rounded text-sm">
                        {keyword}
                      </span>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };
  
  const WordList = ({ title, words }) => {
    const [, forceUpdate] = useState({});
  
    useEffect(() => {
      const timer = setInterval(() => forceUpdate({}), 100); // Force update every 100ms
      return () => clearInterval(timer);
    }, []);
  
    return (
      <div className="mb-4">
        <h3 className="text-lg mb-2">{title}</h3>
        <div className="flex flex-wrap gap-2">
          {words.map((word, index) => (
            <button 
              key={index}
              className={`px-2 py-1 rounded text-sm text-white ${
                usedWordsRef.current.has(word.toLowerCase()) || usedWordsRef.current.has(word)
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              }`}
              onClick={() => addWordToInput(word)}
            >
              {word}
            </button>
          ))}
        </div>
      </div>
    );
  };

  const HeadingSummaryAccordion = ({ headingLevelCounts, headingTypeCounts, isExpanded, onToggle }) => (
    <div className="mt-2 mb-6 border border-gray-700 rounded-lg overflow-hidden">
      <button 
        className="w-full flex justify-between items-center p-2 bg-gray-800 hover:bg-gray-700 transition-colors text-left"
        onClick={onToggle}
      >
        <span className="text-gray-400">Heading and Content Summary</span>
        {isExpanded ? <ChevronUp className="h-4 w-4 text-gray-400" /> : <ChevronDown className="h-4 w-4 text-gray-400" />}
      </button>
      {isExpanded && (
        <HeadingLevelSummary headingLevelCounts={headingLevelCounts} headingTypeCounts={headingTypeCounts} />
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-900 text-white flex">
      <div className={`flex-grow p-4 px-8 transition-all duration-300 ${isPanelExpanded ? 'mr-64' : 'mr-12'}`}>
        <div className="max-w-6xl mx-auto">
          <h1 className="text-3xl mb-6">Optmzr.Tools</h1>
      
          <div className="mb-6 flex space-x-2">
            <Button
              onClick={() => setActiveTab('metadata')}
              variant={activeTab === 'metadata' ? 'default' : 'outline'}
              className={`px-4 py-2 rounded text-white ${
                activeTab === 'metadata'
                  ? 'bg-blue-600'
                  : 'border border-blue-600 hover:bg-blue-600'
              }`}
            >
              Title & Description
            </Button>
            <Button
              onClick={() => setActiveTab('body')}
              variant={activeTab === 'body' ? 'default' : 'outline'}
              className={`px-4 py-2 rounded text-white ${
                activeTab === 'body'
                  ? 'bg-blue-600'
                  : 'border border-blue-600 hover:bg-blue-600'
              }`}
            >
              Body
            </Button>
            <Button
              onClick={() => setActiveTab('keywords')}
              variant={activeTab === 'keywords' ? 'default' : 'outline'}
              className={`px-4 py-2 rounded text-white ${
                activeTab === 'keywords'
                  ? 'bg-blue-600'
                  : 'border border-blue-600 hover:bg-blue-600'
              }`}
            >
              Data
            </Button>
          </div>

        {activeTab === 'metadata' && (
          <div className="space-y-4">
            <div>
              <label className="block mb-2 text-white">Page Title:</label>
              <input
                type="text"
                value={pageTitle}
                onChange={(e) => handlePageTitleChange(e.target.value)}
                onFocus={() => setActiveInput('pageTitle')}
                className="w-full p-2 border rounded bg-gray-800 border-gray-700 text-white"
              />
              {keywordCounts.pageTitle && <KeywordCount counts={keywordCounts.pageTitle} />}
              <KeywordDisplay 
                title="Page Title"
                keywordTypes={['main', 'variations', 'entities', 'lsi', 'yake']}
                text={pageTitle}
                isExpanded={expandedSections.pageTitleWordUsage}
                onToggle={() => toggleAccordion('pageTitleWordUsage')}
              />
            </div>

            <div>
            <label className="block mb-2 text-white">Meta Description:</label>
            <textarea
              value={metaDescription}
              onChange={(e) => handleMetaDescriptionChange(e.target.value)}
              onFocus={() => setActiveInput('metaDescription')}
              className="w-full p-2 border rounded bg-gray-800 border-gray-700 text-white"
              rows="3"
            />
              {keywordCounts.metaDescription && <KeywordCount counts={keywordCounts.metaDescription} />}
              <KeywordDisplay 
                title="Meta Description"
                keywordTypes={['main', 'variations', 'entities', 'lsi', 'yake']}
                text={metaDescription}
                isExpanded={expandedSections.metaDescriptionWordUsage}
                onToggle={() => toggleAccordion('metaDescriptionWordUsage')}
              />
            </div>
          </div>
        )}

      {activeTab === 'body' && (
        <div>
          <h2 className="text-xl font-semibold mb-2">Page Structure</h2>
          <HeadingSummaryAccordion 
            headingLevelCounts={headingLevelCounts}
            headingTypeCounts={headingTypeCounts}
            isExpanded={isHeadingSummaryExpanded}
            onToggle={() => setIsHeadingSummaryExpanded(!isHeadingSummaryExpanded)}
          />
      
      <div className="space-y-6"> {/* Added space-y-6 for vertical spacing between elements */}
      {sections.map((section, index) => (
      <div key={section.id}>
        <div
          className="flex items-center space-x-2 mb-2 bg-gray-800 p-2 rounded shadow border border-gray-700" // Added border class
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragEnter={(e) => handleDragEnter(e, index)}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
        >
          <div className="cursor-move">
            <GripIcon className="h-5 w-5 text-gray-400" />
          </div>
          {section.type === 'heading' ? (
            <>
              <select 
                value={section.level}
                onChange={(e) => updateSection(section.id, { level: parseInt(e.target.value) })}
                className="p-2 border rounded bg-gray-700 border-gray-600 text-white"
              >
                {[1, 2, 3, 4, 5, 6].map(level => (
                  <option key={level} value={level}>H{level}</option>
                ))}
              </select>
              <input
                type="text"
                value={section.content}
                onChange={(e) => handleSectionUpdate(section.id, { content: e.target.value })}
                onFocus={() => {
                  setActiveInput('heading');
                  setActiveHeadingId(section.id);
                }}
                className="flex-grow p-2 border rounded bg-gray-700 border-gray-600 text-white"
                placeholder={`H${section.level} content`}
              />
            </>
          ) : (
            <textarea
              value={section.content}
              onChange={(e) => updateSection(section.id, { content: e.target.value })}
              onFocus={() => setActiveInput('content')}
              className="flex-grow p-2 border rounded bg-gray-700 border-gray-600 text-white"
              rows="3"
              placeholder="Enter content here..."
            />
          )}
          <Button variant="ghost" size="icon" onClick={() => removeSection(section.id)}>
            ×
          </Button>
        </div>
        {keywordCounts[`section${index}`] && <KeywordCount counts={keywordCounts[`section${index}`]} />}
      </div>
    ))}
    </div>
    
    <div className="space-x-2 mt-4">
      {[1, 2, 3, 4, 5, 6].map(level => (
        <Button
          key={level}
          onClick={() => addSection('heading', level)}
          className="px-4 py-2 rounded border border-blue-600 text-white hover:bg-blue-600"
        >
          Add H{level}
        </Button>
      ))}
      <Button
        onClick={() => addSection('content')}
        className="px-4 py-2 rounded border border-blue-600 text-white hover:bg-blue-600"
      >
        Add Content
      </Button>
    </div>
  </div>
)}

      {activeTab === 'keywords' && (
        <div>
          <h2 className="text-xl font-semibold mb-4">Keywords & Entities</h2>
          <KeywordInput type="main" label="Keywords" />
          <KeywordInput type="variations" label="Variations" />
          <KeywordInput type="entities" label="Entities" />
          <KeywordInput type="lsi" label="LSI" />
          <KeywordInput type="yake" label="YAKE" />
        </div>
      )}
      </div>
      </div>
      
      <div 
        className={`fixed right-0 top-0 h-full transition-all duration-300 
          ${isPanelExpanded 
            ? 'w-64 bg-gray-800 border-l border-gray-700' 
            : 'w-0'
          }`}
      >
        <button
          onClick={() => setIsPanelExpanded(!isPanelExpanded)}
          className={`absolute top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-1.5 rounded-l-md 
            ${isPanelExpanded ? '-left-8' : '-left-8'}
            ${!isPanelExpanded ? 'border border-gray-700' : 'border-t border-b border-l border-gray-700'}
          `}
        >
          {isPanelExpanded ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
        </button>
        <div className={`w-full h-full overflow-y-auto p-4 ${isPanelExpanded ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <WordList title="Keywords" words={processKeywords().main} />
          <WordList title="Variations" words={processKeywords().variations} />
          <WordList title="Entities" words={processKeywords().entities} />
          <WordList title="LSI" words={processKeywords().lsi} />
          <WordList title="YAKE" words={processKeywords().yake} />
        </div>
      </div>
    </div>
  );
};

export default SEOOptimizationApp;